import React from 'react';
import { useSelector } from 'react-redux';

import './banner.scss';

const Banner = () => {
    const WEB_URL = useSelector(global => global.initState.WEB_URL);

    return (
        <div className="banner">
            <div
                id="carouselBanner"
                className="carousel slide"
                data-ride="carousel"
            >
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <video
                            autoPlay
                            id="homepagevideo-first"
                            iphone-inline-video=""
                            playsInline
                            poster="https://cdn.orangebookvalue.com/photos/images/web/obv/main-banner.jpg"
                            loop
                            muted
                            preload="auto"
                            controls
                        >
                            <source
                                src={`${WEB_URL}/images/NEW_ResaleKaMRP_1080.mp4`}
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
