import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PostData } from '../../auth/fetchData';
import { setCookie } from '../../elements/Cookie';

import './otp.scss';

class OtpModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            phoneerrors: '',
            isOtp: '',
            Phonedisable: 'hide',
            otp: '',
            otpvisibility: this.props.gotp !== undefined ? this.props.gotp : '',
            otperrors: '',
            formID: this.props.frm !== undefined ? this.props.frm : '',
            customerType: 'dealer'
        };
        this.apiURL = this.props.ENV_WEB_URL;
        this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
        this.handleOtpVerification = this.handleOtpVerification.bind(this);
        this.getOtp = this.getOtp.bind(this);
        this.getOtpveify = this.getOtpveify.bind(this);
        this.resndOtp = this.resndOtp.bind(this);
        this.changePhone = this.changePhone.bind(this);
        this.removeModal = this.removeModal.bind(this);
        this.onFocusEvent = this.onFocusEvent.bind(this);
        this.onKeyUpEvent = this.onKeyUpEvent.bind(this);
        this.getOtpInput = this.getOtpInput.bind(this);
        this.handleOnKeyUpPhone = this.handleOnKeyUpPhone.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.gotp != this.props.gotp) {
            this.setState({
                otpvisibility: newProps.gotp,
                formID: newProps.frm
            });
        }
    }

    handleOnKeyUpPhone(event) {
        const getphoneonup = event.target.value;
        if (getphoneonup !== undefined) {
            const phone_10 = getphoneonup.substring(0, 10);
            document.getElementById('otp_mobile').value = phone_10;
        }
    }

    handleOnKeyDown() {
        const getphone = document.getElementById('otp_mobile').value; // event.target.value;

        const regExp = /^[6789]\d{9}$/;

        if (getphone !== undefined) {
            if (getphone.length > 10 || getphone.length < 10) {
                this.setState({
                    phoneerrors: 'show'
                });
            } else if (getphone.length == 10) {
                this.setState({
                    phone: getphone,
                    phoneerrors: ''
                });
                if (regExp.test(getphone) !== true) {
                    this.setState({
                        phoneerrors: 'show'
                    });
                } else {
                    this.setState({
                        phone: getphone
                    });
                    const data = {
                        phone: getphone
                    };
                    this.getOtp({ url: `${this.apiURL}/leadotp_verify`, data });
                }
            }
        }
    }

    handleOtpVerification(event) {
        const getotp = event.target.value;

        this.setState({
            otp: getotp
        });

        if (getotp !== undefined) {
            const otp_4 = getotp.substring(0, 4);
            document.getElementById('otp_mobile_1').value = otp_4;
            document.getElementById('getOtpSent').style.display = 'none';

            if (getotp.length > 4) {
                document.getElementById('otp_mobile_1').blur();
                this.setState({
                    otperrors: 'show'
                });
            } else if (getotp.length == 4) {
                document.getElementById('otp_mobile_1').blur();

                this.setState({
                    otp: getotp
                });
                const data = {
                    phone: this.state.phone,
                    otp: getotp
                };
                this.getOtpveify({
                    url: `${this.apiURL}/leadotp_verify`,
                    data
                });
            }
            // document.getElementById("otp_mobile_1").blur();
        }
    }

    resndOtp() {
        const data = {
            phone: this.state.phone,
            resent: 1
        };
        document.getElementById('otp_mobile_1').value = '';
        document.getElementById('getOtpSent').style.display = 'block';
        document.getElementById('getOtpSent').innerHTML =
            'OTP Sent Successfully';
        document.getElementById('getOtpfailed').innerHTML = '';

        this.getOtp({ url: `${this.apiURL}/leadotp_verify`, data });
    }

    getOtp({ url, data = {} }) {
        // console.log(this.state.formID);

        PostData(url, data).then(result => {
            const responseJson = result;
            // console.log(responseJson.data.otp_verified);
            if (responseJson.code == 'success') {
                if (responseJson.data.otp_verified == 0) {
                    // document.getElementById('getOtpSuccess').innerHTML='OTP Sent';

                    this.setState({
                        isOtp: 'show',
                        Phonedisable: 'show',
                        otpsent: 'success'
                    });
                } else {
                    this.setState({
                        isOtp: 'hide',
                        otperrors: 'success'
                    });
                    const _otvvv = '';
                    sessionStorage.setItem(
                        'otp_varified',
                        JSON.stringify(this.state.phone)
                    );
                    localStorage.setItem('_otvvv', this.state.phone);
                    localStorage.setItem('_custTpe', this.state.customerType);
                    document.getElementById('getOtpSuccess').innerHTML =
                        'OTP already verified';
                    document.getElementById(
                        `${this.state.formID}_phone`
                    ).value = this.state.phone;
                    document.getElementById(
                        `${this.state.formID}_userType`
                    ).value = this.state.customerType;
                    document.getElementById(this.state.formID).submit();
                }

                // document.getElementById('getOtpfailed').innerHTML='';
            } else {
                this.setState({
                    isOtp: 'show',
                    otperrors: 'show',
                    otpsent: 'hide'
                });

                document.getElementById('getOtpfailed').innerHTML =
                    responseJson.message;
                if (responseJson.error_code == 403) {
                    this.setState({
                        isOtp: 'hide',
                        Phonedisable: ''
                    });
                } else {
                    this.setState({
                        isOtp: 'hide',
                        Phonedisable: '',
                        otpsent: 'hide'
                    });
                }
            }
        });
    }

    getOtpveify({ url, data = {} }) {
        PostData(url, data).then(result => {
            const responseJson = result;

            if (responseJson.code == 'success') {
                this.setState({
                    isOtp: 'show',
                    Phonedisable: 'show',
                    otperrors: 'success'
                });

                const _otvvv = '';
                sessionStorage.setItem(
                    'otp_varified',
                    JSON.stringify(this.state.phone)
                );
                localStorage.setItem('_otvvv', this.state.phone);
                localStorage.setItem('_custTpe', this.state.customerType);
                document.getElementById('getOtpSuccess').innerHTML =
                    responseJson.data.message;
                document.getElementById(`${this.state.formID}_phone`).value =
                    this.state.phone;
                document.getElementById(`${this.state.formID}_userType`).value =
                    this.state.customerType;
                document.getElementById(this.state.formID).submit();
            } else {
                this.setState({
                    isOtp: 'show',
                    otperrors: 'show'
                });

                document.getElementById('getOtpfailed').innerHTML =
                    responseJson.message;
                if (responseJson.error_code == 403) {
                    this.setState({
                        isOtp: 'show',
                        Phonedisable: 'show',
                        otperrors: 'show'
                    });
                } else
                    this.setState({
                        isOtp: 'show',
                        Phonedisable: 'show',
                        otperrors: 'show'
                    });
                // this.props.notification.showNotification('error', responseJson.errors)
            }
        });
    }

    changePhone() {
        this.setState({
            isOtp: 'hide',
            Phonedisable: 'hide',
            otpsent: 'hide'
            // phoneerrors:''
        });
    }

    removeModal() {
        window.location.reload();
        // this.setState({
        //     otpvisibility: 'hide'
        //  })
    }

    getOtpInput(index) {
        return document.getElementById(`otp-input${index}`);
    }

    onKeyUpEvent(event) {
        const eventCode = event.which || event.keyCode;
        const index = event.target.getAttribute('inlen');

        const otplength = event.target.value;
        this.setState({
            otp: `${this.state.otp}${otplength}`
        });
        if (otplength.length === 1) {
            if (index !== 4) {
                // document.getElementById('otp-input2').focus;
            } else {
                // console.log(otplength);
                // console.log(this.state.otp);
                // document.getElementById('otp-input' + index).focus;
            }
        }
        if (eventCode === 8 && index !== 1) {
            // document.getElementById('otp-input'+index-1).focus();
        }
    }

    limitText(limitField, limitCount, limitNum) {
        if (limitField.value.length > limitNum) {
            limitField.value = limitField.value.substring(0, limitNum);
        } else {
            limitCount.value = limitNum - limitField.value.length;
        }
    }

    onFocusEvent(index) {
        for (item = 1; item < index; item++) {
            const currentElement = getOtpInput(item);
            if (!currentElement.value) {
                currentElement.focus();
                break;
            }
        }
    }

    handleCheck(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        if (this.state.otpvisibility == 'show') {
            var divStyle = {
                display: 'block',
                paddingRight: '15px',
                background: [
                    'linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7))'
                ]
            };
        } else {
            var divStyle = {
                display: 'none',
                paddingRight: '15px',
                background: [
                    'linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7))'
                ]
            };
        }
        const otpStyle = {
            padding: '0px 0px 0px 15px',
            letterSpacing: '42px',
            border: '0',
            backgroundImage:
                'linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%)',
            backgroundPosition: 'bottom',
            backgroundSize: '50px 1px',
            backgroundRepeat: 'repeat-x',
            backgroundPositionX: '35px',
            width: '200px'
        };
        const errStyle = {
            position: 'absolute',
            color: 'red',
            bottom: '40px',
            fontSize: '12px'
        };
        const errStyleotpfailed = {
            position: 'absolute',
            color: 'red',
            bottom: '-10px',
            fontSize: '12px'
        };
        const succStyle = {
            position: 'absolute',
            color: 'green',
            bottom: '-15px',
            fontSize: '12px'
        };

        const disStyle = {
            display: 'inline-block',
            marginLeft: '10px',
            color: 'rgb(0, 123, 255)',
            fontSize: '14px',
            fontWeight: 'normal',
            cursor: 'pointer'
        };
        const resendStyle = {
            fontWeight: 'bold',
            color: '#1a0dab'
        };
        const otp_parent_div = {
            marginLeft: '12%'
        };
        const opt_submit_btn = {
            margin: '25px 0px -25px 0px'
        };
        const otp_radio_box = {
            margin: '10px 0px 0px 0px'
        };
        const otp_radio_individual = {
            margin: '0px 0px 0px 70px'
        };

        return (
            <div
                id="modalerrors"
                className="modal show"
                tabIndex="-1"
                role="dialog"
                style={divStyle}
            >
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">
                                <i
                                    className="fa fa-info-circle text-info"
                                    aria-hidden="true"
                                />{' '}
                                OTP Verification
                            </h5>
                        </div>
                        <div className="modal-body _14p thin">
                            {this.state.Phonedisable == 'hide' ? (
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        placeholder="Enter Your Phone No:"
                                        type="number"
                                        name="otp_mobile"
                                        id="otp_mobile"
                                        onKeyUp={this.handleOnKeyUpPhone}
                                        min="0"
                                    />
                                    <div style={otp_radio_box}>
                                        <div> I am a:</div>
                                        <input
                                            type="radio"
                                            id="dealer"
                                            name="customerType"
                                            onClick={this.handleCheck.bind(
                                                this
                                            )}
                                            checked={
                                                this.state.customerType ==
                                                'dealer'
                                                    ? 'checked'
                                                    : ''
                                            }
                                            value="dealer"
                                        />{' '}
                                        Dealer
                                        <input
                                            type="radio"
                                            style={otp_radio_individual}
                                            id="individual"
                                            name="customerType"
                                            onClick={this.handleCheck.bind(
                                                this
                                            )}
                                            checked={
                                                this.state.customerType ==
                                                'individual'
                                                    ? 'checked'
                                                    : ''
                                            }
                                            value="individual"
                                        />{' '}
                                        individual
                                    </div>

                                    <button
                                        className="btn btn-block btn-primary"
                                        type="button"
                                        onClick={this.handleOnKeyDown}
                                        style={opt_submit_btn}
                                    >
                                        Submit
                                    </button>
                                </div>
                            ) : (
                                ''
                            )}
                            {this.state.phoneerrors == 'show' &&
                            this.state.Phonedisable == 'hide' ? (
                                <div style={errStyle}>
                                    {' '}
                                    Please Enter Valid Phone No
                                </div>
                            ) : (
                                ''
                            )}

                            {this.state.Phonedisable == 'show' ? (
                                <h6 className="text-center">
                                    {this.state.phone}{' '}
                                    <span
                                        style={disStyle}
                                        onClick={this.changePhone}
                                    >
                                        {' '}
                                        Edit{' '}
                                    </span>{' '}
                                </h6>
                            ) : (
                                ''
                            )}

                            {this.state.isOtp == 'show' ? (
                                //                                       <div className="form-group">
                                //    <label for="phone">Enter Your OTP:</label>
                                //     <input className="form-control" type="number" name ="otp_mobile" id ="otp_mobile" style={otpStyle}  min ="0" oninput ="this.value = Math.abs(this.value)" onKeyUp={this.handleOtpVerification}/>
                                //                                           </div>
                                <div className="otp-section text-center">
                                    <div style={otp_parent_div}>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="otp_mobile"
                                            id="otp_mobile_1"
                                            style={otpStyle}
                                            min="0"
                                            onKeyUp={this.handleOtpVerification}
                                            maxLength="4"
                                        />
                                        {/* //    <input id="otp-input1" name="otp-input1" type="number" inlen="1" onKeyUp={this.onKeyUpEvent} />
    //    <input id="otp-input2" name="otp-input2" type="number" inlen="2" onKeyUp={this.onKeyUpEvent} />
    //    <input id="otp-input3"name="otp-input3" type="number" inlen="3" onKeyUp={this.onKeyUpEvent} />
    //    <input id="otp-input4"name="otp-input4" type="number" inlen="4" onKeyUp={this.onKeyUpEvent} /> */}
                                    </div>
                                    {this.state.Phonedisable == 'show' ? (
                                        <div className="resend-otp text-center">
                                            Did’nt Receive OTP ?{' '}
                                            <span
                                                className="resend_otp"
                                                onClick={this.resndOtp}
                                            >
                                                Resend
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ) : (
                                ''
                            )}
                            {this.state.otperrors == 'show' ? (
                                <div
                                    style={errStyleotpfailed}
                                    id="getOtpfailed"
                                >
                                    {' '}
                                    Please Enter Valid OTP
                                </div>
                            ) : (
                                ''
                            )}
                            {this.state.otperrors == 'success' ? (
                                <div style={succStyle} id="getOtpSuccess">
                                    {' '}
                                    Otp Verifid
                                </div>
                            ) : (
                                ''
                            )}
                            {this.state.otpsent == 'success' ? (
                                <div style={succStyle} id="getOtpSent">
                                    {' '}
                                    Otp Sent
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={this.removeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state, ownProps) {
    return {
        ENV_WEB_URL: state.initState.WEB_URL ? state.initState.WEB_URL : '',
        ...ownProps
    };
}
export default connect(mapStateToProps)(OtpModal);
