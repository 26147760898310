import React from 'react';
import { useTranslator } from '@eo-locale/react';
import { getLocalizedPath } from '@config/index';

const withLocalizePath = Component => {
    const Wrapper = props => {
        const translator = useTranslator();

        const localizePath = path =>
            getLocalizedPath(path, translator.language);

        return <Component localizePath={localizePath} {...props} />;
    };

    return Wrapper;
};

export default withLocalizePath;
