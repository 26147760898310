import React from 'react';
import { useSelector } from 'react-redux';

import Vehicle from './partials/Vehicle';

import './formOBV.scss';

const FormOBV = () => {
    const [otp, setOPT] = React.useState(null);
    const [customerType, setCustomerType] = React.useState('dealer');

    const categories = useSelector(
        state => state.header.globalData?.categories
    );

    React.useEffect(() => {
        if (localStorage.getItem('_otvvv')) {
            setOPT(localStorage.getItem('_otvvv'));
        }

        if (localStorage.getItem('_custTpe')) {
            setCustomerType(localStorage.getItem('_custTpe'));
        }
    }, []);

    return (
        // eslint-disable-next-line jsx-a11y/aria-role
        <div className="form" role="obv">
            <div className="tab-content bg-white">
                <div
                    className="tab-pane fade show active"
                    id="pills-vehicle"
                    role="tabpanel"
                    aria-labelledby="pills-vehicle-tab"
                >
                    <Vehicle
                        categories={categories}
                        IsLocalOtp={otp}
                        IsCustType={customerType}
                    />
                </div>
            </div>
        </div>
    );
};

export default FormOBV;
