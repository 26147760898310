import React from 'react';
import { Text } from '@eo-locale/react';
import { useSelector } from 'react-redux';
import FormOBV from '@components/FormOBV/Index';
import QueriesComp from './QueriesComp';

import './Top.scss';

const Top = () => {
    const SALE_ON = useSelector(state => state.initState.SALE_ON);

    return (
        <>
            <div className="container">
                <div className="header mainBanner">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 left-block">
                            <FormOBV />
                        </div>
                        <div className="col-lg-8 col-md-6 right-block">
                            <div className="context">
                                <div className="obv-text-white">
                                    <h1 className="obv-display-block obv-width-100 obv-margin-top-30">
                                        <Text
                                            id="Indias_De_Facto_Standard"
                                            defaultMessage="India's De Facto Standard"
                                        />
                                    </h1>
                                    <small className="obv-font-size-12 obv-font-weight-500 obv-display-block obv-width-100">
                                        <Text
                                            id="For_Used_Vehicle_Pricing"
                                            defaultMessage="For Used Vehicle Pricing"
                                        />
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="badge badge-msite">
                <div className="container">
                    <QueriesComp />
                </div>
            </div>
            <div className="badge badge-website">
                <div className="container">
                    <QueriesComp />
                </div>
            </div>

            {SALE_ON === 1 ? (
                <>
                    <div
                        className="d-md-block d-none"
                        style={{
                            display: 'block',
                            position: 'relative',
                            zIndex: '999'
                        }}
                    >
                        <a
                            href="https://droom.in/obv/premium-report"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                alt="banner"
                                src="https://cdn1.acedms.com/photos/images/banner/OBV_strip-banner.gif"
                                style={{ width: '100%' }}
                            />
                        </a>
                    </div>
                    <div className="d-lg-none d-md-none">
                        <a
                            href="https://droom.in/obv/premium-report"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                alt="banner"
                                src="https://cdn1.acedms.com/photos/images/banner/OBV_1549x481_Landingpage_480x280_gif.gif"
                                style={{ width: '100%' }}
                            />
                        </a>
                    </div>

                    <div className="target pt-3">
                        <div className="container">
                            <div className="row msite">
                                <div className="col-6 text-left">
                                    <div className="app-img">
                                        <a href="https://apps.apple.com/us/app/orange-book-value/id1120532296">
                                            <img
                                                src="https://cdn1.droom.in/photos/images/Marketing/apple-o.png"
                                                alt=""
                                                className=""
                                            />
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=in.droom.online_obv_app">
                                            <img
                                                src="https://cdn1.droom.in/photos/images/Marketing/playstore-o.png"
                                                alt=""
                                                className=""
                                            />
                                        </a>
                                        <p className="ml-3 title mt-1 mb-0">
                                            Download App
                                        </p>
                                    </div>
                                </div>
                                <div className="col-6 text-right">
                                    <div className="social-list p-2">
                                        <a href="https://www.facebook.com/orangebookvalue">
                                            <img
                                                src="https://cdn1.droom.in/photos/images/Marketing/facebook-o.png"
                                                alt=""
                                                className=""
                                            />
                                        </a>
                                        <a href="https://www.instagram.com/orangebookvalue/">
                                            <img
                                                src="https://cdn1.droom.in/photos/images/Marketing/instagram-o.png"
                                                alt=""
                                                className=""
                                            />
                                        </a>
                                        <a href="https://twitter.com/OrangeBookValue">
                                            <img
                                                src="https://cdn1.droom.in/photos/images/Marketing/twitter-o.png"
                                                alt=""
                                                className=""
                                            />
                                        </a>
                                        <a href="https://www.linkedin.com/company/orange-book-value/about/">
                                            <img
                                                src="https://cdn1.droom.in/photos/images/Marketing/linkedin-o.png"
                                                alt=""
                                                className=""
                                            />
                                        </a>
                                        <a href="https://in.pinterest.com/orangebookvalue/_saved/">
                                            <img
                                                src="https://cdn1.droom.in/photos/images/Marketing/pinterest-o.png"
                                                alt=""
                                                className=""
                                            />
                                        </a>
                                        <p className="text-right title mt-1 mb-0">
                                            Follow us
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default Top;
