import React from 'react';
import { useTranslator } from '@eo-locale/react';

const withTranslator = Component => {
    const Wrapper = props => {
        const translator = useTranslator();

        return <Component translator={translator} {...props} />;
    };

    return Wrapper;
};

export default withTranslator;
