import React from 'react';
import loadable from '@loadable/component';
import LazyLoad, { forceCheck } from 'react-lazyload';
import Banner from '@components/Banner/Index';
import Top from './partials/Top';

import './home.scss';
import './home-global.scss';

const LoadableStatic = loadable(() => import('./partials/Static'), {
    fallback: <div>Loading...</div>
});

const HomeComponent = () => {
    React.useEffect(() => {
        forceCheck();
    });

    return (
        <div className="home">
            <Banner />
            <Top />

            <LazyLoad once>
                <LoadableStatic />
            </LazyLoad>
        </div>
    );
};

export default HomeComponent;
